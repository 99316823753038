import {Controller} from '@hotwired/stimulus'
import {Chart, registerables} from 'chart.js'

Chart.register(...registerables)

export default class extends Controller {
    static values = {
        data: Object,
        plugins: Object
    }

    connect() {
        this.chart = new Chart(this.element, {
            type: 'line',
            data: this.dataValue,
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            callback: value => formatMoney(value)
                        }
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: value => (formatMoney(value.formattedValue))
                        }
                    },
                    ...(this.pluginsValue || {})
                }, 
            }
        })
    }

    disconnect() {
        this.chart.destroy()
    }
}

const formatMoney = value => {
    return ` ${value.toString().replaceAll(',', '')}€`
}